.cart {
    padding: 20px;
    border: 1px solid #ccc;
    border-radius: 5px;
    width: 300px;
    margin: 20px auto;
}

.cart-item {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
}

.cart-item-image {
    width: 50px;
    height: 50px;
    margin-right: 10px;
}

.cart-item-details {
    flex-grow: 1;
}
:root {
    --bg: #4e2009;
    --card-bg: #ffffff;
}

body {
    background: var(--bg);
}

.navigation-bar {
    background-color: #4e2009;
    border: #041221;
    margin: auto;
}

.navigation-bar .title {
    color: white;
    text-align: center;
    padding: 14px 16px;
    font-size: xx-large;
    text-decoration: none;
}

.navigation-bar .subtitle {
    color: white;
    text-align: center;
    padding: 14px 16px;
    font-size: medium;
    text-decoration: none;
}

.box {

    background: var(--card-bg);
}


input::placeholder {
    color: antiquewhite;
}

.navbar {
    background: var(--card-bg);
}

.navbar-item {
    color: ghostwhite;
}

/* Card start*/
.card {
    overflow: hidden;
    background: ghostwhite;
    color: var(--bg);
}

.card.large {
    border-radius: 5px;
}

.title.no-padding {
    margin-bottom: 0 !important;
}

.footer {
    background: var(--card-bg);
    color: ghostwhite;
}

.footer p,
strong {
    color: ghostwhite
}

.footer a:hover {
    color: crimson;
}

.fa {
    color: ghostwhite;
    margin: 10px
}
.book-create {
    background-color: #00d1b2;
    bottom: 0;
    padding: 20px 40px 30px;
    position: relative;
    /* Make it fixed when moved to new page */
    width: 100%
}

.book-create h2 {
    color: #fff;
    font-size: 24px;
    font-weight: 700
}

.book-create form {
    align-items: flex-start;
    display: flex;
    flex-direction: column
}